import React from "react";

import { getPreviewFramePrintFontSize } from "@/utils/qrEditorHelper";

import { QRFRameToPrintProps } from "../types";

import { uriText } from "./uri";

import "../styles.scss";

export const MobileFrameToDownload = (
  props: QRFRameToPrintProps
): React.SVGProps<SVGSVGElement> => {
  const {
    text,
    textColor,
    frameColor,
    frameBackgroundColor,
    height = 1200,
    qrSvg,
    width = 900,
  } = props;

  const fontSize = getPreviewFramePrintFontSize(text?.length);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <g>
        <title>Layer 1</title>
        <rect
          stroke={frameBackgroundColor}
          strokeWidth="28"
          id="svg_487"
          height="750.99999"
          width="750.99999"
          y="240.80001"
          x="74.99998"
          fill="#ffffff"
        />
        <path
          stroke="null"
          id="svg_18"
          fill={frameColor}
          d="m0,75.91666c0,-41.37535 33.54131,-74.91666 74.91666,-74.91666l749.16664,0c41.37522,0 74.91666,33.54131 74.91666,74.91666l0,1048.8333c0,41.37522 -33.54144,74.91666 -74.91666,74.91666l-749.16664,0c-41.37535,0 -74.91666,-33.54144 -74.91666,-74.91666l0,-1048.8333zm449.50123,1093.7833c37.23733,0 67.425,-30.18767 67.425,-67.425c0,-37.23733 -30.18767,-67.425 -67.425,-67.425c-37.23858,0 -67.425,30.18767 -67.425,67.425c0,37.23733 30.18642,67.425 67.425,67.425zm-0.00499,-18.72917c26.89383,0 48.69583,-21.802 48.69583,-48.69583c0,-26.89383 -21.802,-48.69583 -48.69583,-48.69583c-26.89383,0 -48.69583,21.802 -48.69583,48.69583c0,26.89383 21.802,48.69583 48.69583,48.69583zm389.5679,-925.2208l-779.13331,0l0,779.13331l779.13331,0l0,-779.13331z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
        <g
          xmlns="http://www.w3.org/2000/svg"
          transform="matrix(6.25794 0 0 6.25794 0 190)"
          stroke="null"
          id="svg_485"
        >
          <g
            dangerouslySetInnerHTML={{ __html: qrSvg }}
            stroke="null"
            id="svg_486"
          ></g>
        </g>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="260"
          y="0"
          x="0"
        >
          <style>
            {`
                @font-face {
                  font-family: 'Nunito Sans';
                  src: url(${uriText});
                  font-weight: bold
                }
              `}
          </style>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            strokeWidth="3px"
            fill="none"
          />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill={textColor}
            fontFamily="Nunito Sans"
            style={{ fontSize }}
          >
            {text}
          </text>
        </svg>
      </g>
    </svg>
  );
};
