import React, { useEffect, useState } from "react";

import { TooltipProps } from "./types";

import "./styles.scss";

export function Tooltip({
  text,
  children,
  position = "top",
  id,
}: TooltipProps) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const tooltipOn = () => setShowTooltip(true);
  const tooltipOff = () => setShowTooltip(false);

  const closeTooltip = (ev: KeyboardEvent) => {
    if (ev.key === "Escape") setShowTooltip(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", closeTooltip);

    return () => {
      document.removeEventListener("keydown", closeTooltip);
    };
  }, []);

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(false);
      }, 8000);
    }
  }, [showTooltip]);

  return (
    <div
      data-testid={"tooltip"}
      className="tooltip"
      onMouseEnter={tooltipOn}
      onFocus={tooltipOn}
      onMouseLeave={tooltipOff}
      onBlur={tooltipOff}
    >
      {showTooltip && (
        <div
          className={`tooltip-text ${position}`}
          data-placement={position}
          role="tooltip"
          aria-hidden="true"
          id={id}
        >
          <i></i>
          {text}
        </div>
      )}
      {children}
    </div>
  );
}
