import React from "react";
import classNames from "classnames";

import { TypographyProps } from "./types";


export const Typography = ({
  Tag,
  children,
  spacing,
  size,
  classname,
  onClick,
  dataQA,
  id,
  style,
  title,
}: TypographyProps) => {

  const classnames = classNames("", {
    [classname]: classname,
    [`u-spacing-${spacing}`]: spacing,
    [`body-${size}`]: size,
  });

  if (typeof children === "string") {
    return (
      <Tag
        data-testid="Typography"
        data-qa={dataQA}
        className={classnames}
        dangerouslySetInnerHTML={{ __html: children }}
        id={id}
        style={style}
        onClick={onClick}
        title={title}
      />
      
    );
  } else {
    return (
      <Tag
        data-testid="Typography"
        data-qa={dataQA}
        className={classnames}
        id={id}
        style={style}
        onClick={onClick}
        title={title}
      >
        {children}
      </Tag>
    );
  }
};
