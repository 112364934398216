import { BlackFrameToDownload } from "./Black";
import { BoardFrameToDownload } from "./Board";
import { ButtonFrameToDownload } from "./button";
import { CafeFrameToDownload } from "./Cafe";
import { EnvelopeFrameToDownload } from "./Envelope";
import { MobileFrameToDownload } from "./Mobile";
import { MuseumFrameToDownload } from "./Museum";
import { ReceiptFrameToDownload } from "./Receipt";
import { ShoppingFrameToDownload } from "./Shopping";
import { TicketFrameToDownload } from "./Ticket";
import { WhiteFrameToDownload } from "./White";

export {
  BlackFrameToDownload,
  BoardFrameToDownload,
  ButtonFrameToDownload,
  CafeFrameToDownload,
  EnvelopeFrameToDownload,
  MobileFrameToDownload,
  MuseumFrameToDownload,
  ReceiptFrameToDownload,
  ShoppingFrameToDownload,
  TicketFrameToDownload,
  WhiteFrameToDownload,
};
