import { map, forOwn, capitalize, split } from "lodash";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { LinkInterface } from "@/components/Links/types";
import { QRProps } from "@/contexts/QREditorContext/types";
import {
  SocialNetwork,
  QRAppsType,
  QRBusinessType,
  QRImageType,
  QRLinksType,
  QRPDFType,
  QRUrlType,
  QRVideoType,
  QRYoutubeType,
  QRWebsiteType,
  QRWifiType,
  QRStyle,
  QRType,
  QRVcardType,
  QRCodeDataAIStatus,
  QRSocialType,
  QRSocialMedia,
  QREventType,
  DateTime,
  ThemeColor,
  Icon_Facility,
} from "@/types/qr";

import { LINK_ID_PREFIX } from "@components/Links";
import { QRAppsAPIPayload } from "../types/qr/apps";
import { QRBusinessAPIPayload } from "../types/qr/business";
import { QRImagesAPIPayload } from "../types/qr/images";
import { QRLinksAPIPayload } from "../types/qr/links";
import { QRPdfAPIPayload } from "../types/qr/pdf";
import { QRUrlAPIPayload } from "../types/qr/url";
import { QRVideoAPIPayload } from "../types/qr/video";
import { QRWebsiteAPIPayload } from "../types/qr/website";
import { QRWifiAPIPayload } from "../types/qr/wifi";
import { QRYoutubeAPIPayload } from "../types/qr/youtube";
import { QRVcardAPIPayload } from "../types/qr/vcard";
import { QRSocialAPIPayload, SocialChannel } from "../types/qr/social";
import {
  API_Facility,
  FacilitiesObjectType,
  QREventAPIPayload,
} from "../types/qr/event";
import { QRStyleAPIPayload } from "../types/types";
import { QrCodeResponse } from "@api/getAllQrCodes/types";
import { FrameType } from "@/components/QRFrame/types";
import { BusinessHours } from "@/components/BusinessHours/types";
import { checkValidURL } from "@/utils/formHelper";
import { optimizeCloudinaryImage } from "@/services/CloudinaryService";
import { QR_PAGE_INDEX } from "@/utils/qrEditorNavigatorHelper";
import { formatNumberTimeToString } from "@/utils/timerHelper";
import { DEFAULT_DATE_FORMAT } from "@/components/Calendar";
import { Link, Social } from "@/types/types";
import { socials } from "@/internals/constants/socials";

dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_QR_CODE_NAME = "Your QR Code";

export class QREditorAPItoDataConversor {
  static getQRData(apiResponse: QrCodeResponse): QRProps<any> {
    const type = this.getType(apiResponse.type);
    return {
      type,
      currentPageIndex: QR_PAGE_INDEX.ADD_CONTENT,
      isValidForm: true,
      previousPageIndex: QR_PAGE_INDEX.SELECT_QR_TYPE,
      data: this.getData(type, apiResponse.content),
      qrStyle: this.getQRStyle(
        apiResponse.style,
        apiResponse.ai?.selectedImageUrl &&
          apiResponse.ai?.status === QRCodeDataAIStatus.COMPLETE
      ),
      qrCodeId: apiResponse.id,
      qrCodeReference: apiResponse.reference,
      ai: apiResponse.ai,
    };
  }

  static getQRStyle(style: QRStyleAPIPayload, isAI?: boolean): QRStyle {
    const {
      backgroundColor,
      dotsColor,
      dotsStyle,
      cornerBackgroundColor,
      cornerBorderColor,
      cornerStyle,
      imageUrl,
      frameBackgroundColor,
      frameColor,
      frameStyle,
      frameText,
      frameTextColor,
    } = style;

    //TODO: Change styleType in order to get value based on the api data
    return {
      styleType: isAI ? "AI" : "STANDARD",
      backgroundColor,
      dotsColor,
      dotsStyle,
      cornerBackgroundColor,
      cornerBorderColor,
      cornerStyle: cornerStyle,
      logoUrl: imageUrl,
      frameBackgroundColor,
      frameColor,
      frameStyle: frameStyle as FrameType,
      frameText,
      frameTextColor,
    };
  }

  public static getType(type: string) {
    switch (type) {
      case "Image Gallery":
        return "image" as QRType;
      case "List of links":
        return "listoflinks" as QRType;
      case "Webpage":
        return "url" as QRType;
      case "Social Media":
        return "social" as QRType;
      default:
        return type.toLowerCase() as QRType;
    }
  }

  public static QRTypeTransform(type: string) {
    let qrTypeTransformed: string;

    switch (type) {
      case "apps":
        qrTypeTransformed = "Apps";
        break;
      case "business":
        qrTypeTransformed = "Business";
        break;
      case "image":
        qrTypeTransformed = "Image";
        break;
      case "listoflinks":
        qrTypeTransformed = "Links";
        break;
      case "pdf":
        qrTypeTransformed = "PDF";
        break;
      case "url":
        qrTypeTransformed = "URL";
        break;
      case "video":
        qrTypeTransformed = "Video";
        break;
      case "website":
        qrTypeTransformed = "Website";
        break;
      case "wifi":
        qrTypeTransformed = "Wifi";
        break;
      case "youtube":
        qrTypeTransformed = "YouTube";
        break;
      case "vcard":
        qrTypeTransformed = "vCard";
        break;
      case "social":
        qrTypeTransformed = "Social Media";
        break;
      case "event":
        qrTypeTransformed = "Event";
        break;
      default:
        qrTypeTransformed = "";
        break;
    }
    return qrTypeTransformed;
  }

  private static getData(type: QRType, data: any) {
    let result;

    switch (type) {
      case "apps":
        result = this.qrApp(data);
        break;
      case "business":
        result = this.qrBusiness(data);
        break;
      case "image":
        result = this.qrImage(data);
        break;
      case "listoflinks":
        result = this.qrLinks(data);
        break;
      case "pdf":
        result = this.qrPdf(data);
        break;
      case "url":
        result = this.qrUrl(data);
        break;
      case "video":
        result = this.qrVideo(data);
        break;
      case "website":
        result = this.qrWebsite(data);
        break;
      case "wifi":
        result = this.qrWifi(data);
        break;
      case "youtube":
        result = this.qrYoutube(data);
        break;
      case "vcard":
        result = this.qrVcard(data);
        break;
      case "social":
        result = this.qrSocialMedia(data);
        break;
      case "event": {
        result = this.qrEvent(data);
        break;
      }
      default:
        result = undefined;
        break;
    }

    return result;
  }

  private static getColor = (
    themeColor: ThemeColor
  ): { backgroundColor: string; buttonColor: string } => {
    return {
      backgroundColor: themeColor.background,
      buttonColor: themeColor.button,
    };
  };

  private static getSocials = (socials: Social): SocialNetwork[] => {
    const socialNetworks = [];
    forOwn(socials, (value, key) =>
      socialNetworks.push({ id: key, name: capitalize(key), value: value })
    );

    return socialNetworks;
  };

  private static getSocialsExtended = (
    socialChannels: SocialChannel[]
  ): QRSocialMedia[] => {
    return socialChannels.map((social) => {
      const { identifier, text, url } = social;
      const socialName = socials.find(
        (social) => social.id === identifier
      )?.name;

      return {
        id: identifier,
        name: socialName,
        url: {
          value: checkValidURL(url)|| "",
        },
        text: {
          value: text,
        },
      } as QRSocialMedia;
    });
  };

  private static getFacilities = (
    facilities: FacilitiesObjectType
  ): Icon_Facility[] => {
    const facilityList = [];

    const facilityNames = {
      [API_Facility.accommodation]: Icon_Facility.accommodation,
      [API_Facility.bar]: Icon_Facility.bar,
      [API_Facility.cafe]: Icon_Facility.cafe,
      [API_Facility.babyStroller]: Icon_Facility.childFriendly,
      [API_Facility.parking]: Icon_Facility.parking,
      [API_Facility.petFriendly]: Icon_Facility.petFriendly,
      [API_Facility.restaurant]: Icon_Facility.restaurant,
      [API_Facility.toilet]: Icon_Facility.restrooms,
      [API_Facility.restRooms]: Icon_Facility.seating,
      [API_Facility.bus]: Icon_Facility.nearPublicTransport,
      [API_Facility.taxi]: Icon_Facility.taxi,
      [API_Facility.wheelchairAccess]: Icon_Facility.wheelchairAccess,
      [API_Facility.wifi]: Icon_Facility.wifi,
    };

    forOwn(
      facilities,
      (facilityValue, facilityKey) =>
        facilityValue && facilityList.push(facilityNames[facilityKey])
    );

    return facilityList as Icon_Facility[];
  };

  private static getLinks = (links: Link[]): LinkInterface[] => {
    return map(links, (link, idx) => {
      return {
        id: `${LINK_ID_PREFIX}-${idx}`,
        linkImageUrl: link.image,
        linkText: link.text,
        linkUrl: link.url,
      };
    });
  };

  private static getBusinessHours = (
    data: QRBusinessAPIPayload
  ): BusinessHours => {
    const propertyExists = (property: any) => (property ? property : undefined);

    return {
      format:
        propertyExists(data["opening-hours-format"]) &&
        data["opening-hours-format"] === "AM-PM"
          ? "AM/PM"
          : "24",
      days: {
        monday: propertyExists(data["opening-hours-day-monday"]),
        tuesday: propertyExists(data["opening-hours-day-tuesday"]),
        wednesday: propertyExists(data["opening-hours-day-wednesday"]),
        thursday: propertyExists(data["opening-hours-day-thursday"]),
        friday: propertyExists(data["opening-hours-day-friday"]),
        saturday: propertyExists(data["opening-hours-day-saturday"]),
        sunday: propertyExists(data["opening-hours-day-sunday"]),
      },
    };
  };

  private static getDateAndHoursFromIsoString = (
    key: "time-start" | "time-end",
    data: QREventAPIPayload
  ): DateTime => {
    const stringDate = data[key];
    const isAllDay = data["time-all-day"];
    const utcDate = dayjs.utc(stringDate);

    if (isAllDay) {
      return {
        date: utcDate.toISOString(),
        time: undefined,
      };
    }

    const hour = formatNumberTimeToString(utcDate.get("hour")) || "00";
    const minute = formatNumberTimeToString(utcDate.get("minute")) || "00";

    const time = `${hour}:${minute}`;

    const date = dayjs.utc(stringDate).format(DEFAULT_DATE_FORMAT);

    return {
      date,
      time,
    };
  };

  private static qrSocialMedia(data: QRSocialAPIPayload): QRSocialType {
    return {
      typeName: "Social Media",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      logoUrl: data["info-logo"],
      themeColor: this.getColor(data.color),
      headline: data["info-headline"],
      description: data["info-description"],
      links: this.getSocialsExtended(data["channels-social"]),
    } as QRSocialType;
  }

  private static qrEvent(data: QREventAPIPayload): QREventType {
    return {
      typeName: "Event",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      themeColor: this.getColor(data.color),
      logoUrl: data["info-image"],
      infoTitle: data["info-title"],
      infoDescription: data["info-description"],
      infoActionTitle: data["info-action-title"],
      infoActionUrl: data["info-action-url"],
      eventDateTime: {
        format: data["time-format"],
        allDay: data["time-all-day"],
        timezone: data["time-timezone"],
        start: this.getDateAndHoursFromIsoString("time-start", data),
        end: this.getDateAndHoursFromIsoString("time-end", data),
        actionTitle: data["time-action-title"],
      },
      locationAddress: data["location-address"],
      locationNumeration: data["location-numeration"],
      locationPostalCode: data["location-postal-code"],
      locationCity: data["location-city"],
      locationState: data["location-state"],
      locationCountry: data["location-country"],
      organizerName: data["organizer-name"],
      organizerPhone: data["organizer-phone"],
      organizerEmail: data["organizer-email"],
      organizerWebsite: data["organizer-website"],
      organizerAbout: data["organizer-about"],
      facilities: this.getFacilities(data.facilities),
    } as QREventType;
  }

  private static qrWebsite(data: QRWebsiteAPIPayload): QRWebsiteType {
    return {
      buttonText: data["info-action-title"],
      buttonUrl: data["info-action-url"],
      companyName: data["info-company"],
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      title: data["info-title"],
      subtitle: data["info-subtitle"],
      typeName: "Website",
      logoUrl: data["info-image"],
      socialNetworks: this.getSocials(data.social),
      themeColor: this.getColor(data.color),
    };
  }

  private static qrApp(data: QRAppsAPIPayload): QRAppsType {
    return {
      typeName: "Apps",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      appsCompany: data["info-company"],
      appsDescription: data["info-description"],
      appsName: data["info-name"],
      appsWebsite: data["info-website"],
      logoUrl: optimizeCloudinaryImage({ url: data["info-logo"] }),
      appLinks: map(this.getSocials(data.apps), (app) => ({
        ...app,
        value: app.value,
        mod: "-large",
      })),
      themeColor: this.getColor(data.color),
    };
  }

  private static qrBusiness(data: QRBusinessAPIPayload): QRBusinessType {
    return {
      typeName: "Business",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      aboutTheCompany: data.about,
      buttonText: data["info-action-title"],
      buttonUrl: data["info-action-url"],
      businessHours: this.getBusinessHours(data),
      city: data["location-city"],
      country: data["location-country"],
      state: data["location-state"],
      numeration: data["location-numeration"],
      zipCode: data["location-postal-code"],
      address: data["location-address"],
      companyName: data["info-company"],
      contactEmail: data["contact-email"],
      contactName: data["contact-name"],
      contactPhone: data["contact-phone"],
      contactWebsite: data["contact-website"],
      logoUrl: optimizeCloudinaryImage({ url: data["info-logo"] }),
      subtitle: data["info-subtitle"],
      title: data["info-title"],
      facilities: this.getFacilities(data.facilities),
      themeColor: this.getColor(data.color),
      socialNetworks: this.getSocials(data.social),
    };
  }

  private static qrImage(data: QRImagesAPIPayload): QRImageType {
    return {
      typeName: "Image",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      albumDescription: data["information-description"],
      albumTitle: data["information-title"],
      albumWebsite: data["information-website"],
      buttonText: data["information-button-text"],
      buttonUrl: data["information-url"],
      themeColor: this.getColor(data.color),
      imageUrls: map(data.images, (image) => {
        const imageNameArray = split(image.url, "/");

        return {
          name: imageNameArray[imageNameArray.length - 1],
          size: "0",
          url: optimizeCloudinaryImage({ url: image.url }),
        };
      }),
    };
  }

  private static qrLinks(data: QRLinksAPIPayload): QRLinksType {
    return {
      typeName: "Links",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      description: data["information-description"],
      title: data["information-title"],
      themeColor: this.getColor(data.color),
      socialNetworks: this.getSocials(data.social),
      logoUrl: optimizeCloudinaryImage({ url: data["information-logo"] }),
      links: this.getLinks(data.links),
    };
  }

  private static qrPdf(data: QRPdfAPIPayload): QRPDFType {
    const pdfArray = split(data["pdf-upload"], "/");

    return {
      typeName: "PDF",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      companyName: data["info-company"],
      themeColor: this.getColor(data.color),
      websiteUrl: data["info-website"],
      pdfName: data["info-name"],
      pdfDescription: data["info-description"],
      pdfFileUrl: data["pdf-upload"],
      pdfFileName: pdfArray[pdfArray.length - 1],
      size: "0",
    };
  }

  private static qrUrl(data: QRUrlAPIPayload): QRUrlType {
    return {
      typeName: "URL",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      qrUrlLink: data["field-url"],
    };
  }

  private static qrVcard(data: QRVcardAPIPayload): QRVcardType {
    return {
      typeName: "vCard",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      themeColor: this.getColor(data.color),
      logoUrl: optimizeCloudinaryImage({ url: data["info-image"] }),
      fullName: data["info-full-name"],
      email: data["info-email"],
      mobilePhone: data["info-mobile-phone"],
      landlinePhone: data["info-landline-phone"],
      fax: data["info-fax"],
      website: data["info-website"],
      companyName: data["info-company-name"],
      profession: data["info-profession"],
      summary: data["info-summary"],
      address: data["info-address"],
      numeration: data["info-numeration"],
      zipCode: data["info-zip-code"],
      city: data["info-city"],
      state: data["info-state"],
      country: data["info-country"],
      socialNetworks: this.getSocials(data.social),
    };
  }

  private static qrVideo(data: QRVideoAPIPayload): QRVideoType {
    return {
      typeName: "Video",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      title: data["info-name"],
      description: data["info-description"],
      companyName: data["info-company"],
      videoUrls: map(data.videos, (video) => {
        return {
          name: video.name,
          size: "0",
          url: optimizeCloudinaryImage({ url: video.url, isVideo: true }),
          type: "video",
        };
      }),
      buttonText: data["info-button"],
      buttonUrl: data["info-url"],
      socialNetworks: this.getSocials(data.social),
      themeColor: this.getColor(data.color),
    };
  }

  private static qrWifi(data: QRWifiAPIPayload): QRWifiType {
    return {
      typeName: "Wifi",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      qrNetworkName: data["network-name"],
      qrNetworkPassword: data["network-password"],
      qrNetworkSecurityType: data["network-security-type"] || "No password",
      qrNetworkHidden: data["network-hidden-password"],
    };
  }

  private static qrYoutube(data: QRYoutubeAPIPayload): QRYoutubeType {
    return {
      typeName: "YouTube",
      qrName: data["qr-name"] || DEFAULT_QR_CODE_NAME,
      youtubeUrl: data["youtube-url"],
    };
  }
}
