import { BlackFrame } from "./Black";
import { BoardFrame } from "./Board";
import { ButtonFrame } from "./button";
import { CafeFrame } from "./Cafe";
import { EnvelopeFrame } from "./Envelope";
import { MobileFrame } from "./Mobile";
import { MuseumFrame } from "./Museum";
import { ReceiptFrame } from "./Receipt";
import { ShoppingFrame } from "./Shopping";
import { TicketFrame } from "./Ticket";
import { WhiteFrame } from "./White";

export {
  BlackFrame,
  BoardFrame,
  ButtonFrame,
  CafeFrame,
  MobileFrame,
  MuseumFrame,
  ReceiptFrame,
  ShoppingFrame,
  TicketFrame,
  WhiteFrame,
  EnvelopeFrame,
};
