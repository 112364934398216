import React from "react";
import { GrCircleQuestion } from "react-icons/gr";
import { Tooltip } from "@/components/Tooltip";

export const InputTooltipHelper = ({ helper, id }) => {
  const { item, tooltip } = helper;
  const { text, position } = tooltip;

  const icon = (item) => {
    if (item === "help") return <GrCircleQuestion className="helper-icon" />;
  };

  return (
    <Tooltip text={text} position={position} id={`${item}-${id}`}>
      {icon(item)}
    </Tooltip>
  );
};
