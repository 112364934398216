import React from "react";
import { map } from "lodash";
import { ReactSVG } from "react-svg";
import { FiEdit2 } from "react-icons/fi";

import { Button } from "../Button";
import { Lazy } from "../Lazy";

import { convertStringAspectRatioToNumber } from "@/components/ImageCropper/utils";
import {
  optimizeCloudinaryImage,
  uploadImages,
} from "@/services/CloudinaryService";
import { fileToBase64 } from "@/utils/uploadHelper";
import { ConnectForm } from "@/utils/formHelper";

import { getImageCropperModal } from "@/modals/ImageCropperModal";

import { ModalContext } from "@/contexts/ModalContext";
import { QREditorContext } from "@/contexts/QREditorContext";

import { ImageUploadProps } from "./types";

import "./styles.scss";

export const ImageUpload = ({
  title,
  id,
  dataQA,
  defaultValue,
  showEditor = undefined,
  onClick,
}: ImageUploadProps) => {
  const modalContext = React.useContext(ModalContext);
  const qrEditorContext = React.useContext(QREditorContext);

  const [images, setImages] = React.useState(
    !defaultValue || !defaultValue[0] ? [] : [defaultValue]
  );
  const [imageURLs, setImageURLs] = React.useState([]);

  React.useEffect(() => {
    if (images.length < 1) {
      onClick && onClick([]);
      setImageURLs([]);
      return;
    }

    onClick && onClick(images);
    setImageURLs(map(images, (img) => img));
  }, [images]);

  const uploadImage = async () => {
    await uploadImages({
      image: images[0],
      prefixPublicId: qrEditorContext?.qrData?.qrCodeId || null,
    });
  };

  const changeImage = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const promise = new Promise<void>((resolve) => {
      reader.onload = () => {
        const fileBlob = new Blob([reader.result], {
          type: "image",
        });

        const fileURL = URL.createObjectURL(fileBlob);
        setImages([fileURL]);
        resolve();
      };
    });

    reader.readAsArrayBuffer(file);
    await promise;
    e.target.value = "";
  };

  const onChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (showEditor) {
      const fileToBlob = await fileToBase64(e.target.files[0]);
      modalContext.handleModal({
        content: getImageCropperModal({
          data: {
            image: fileToBlob,
            aspectRatio:
              showEditor === "profile"
                ? "profile"
                : convertStringAspectRatioToNumber(showEditor),
          },
          onConfirmButton: (blob: Blob) => {
            setImages([blob]);
            modalContext.handleModal();
          },
        }),
        modalProps: {
          className:
            "u-modal u-modal--big u-box u-box u-box--small u-text-center image-cropper_modal",
        },
        modalOverlayClassName: "image-cropper_modal_overlay",
      });
    } else {
      changeImage(e);
    }
  };

  React.useEffect(() => {
    setImages(!defaultValue || !defaultValue[0] ? [] : [defaultValue]);
  }, [defaultValue]);

  return (
    <div data-testid="ImageUpload" className="imageUpload" data-qa={dataQA}>
      {title && <p>{title}</p>}
      <div className="imageUpload__wrapper">
        <div>
          <input
            type="file"
            name="file"
            id={id || "file"}
            accept="image/*"
            onChange={async (e) => await onChangeImage(e)}
            className="imageUpload__input"
            style={{ width: 10, height: 10, color: "blue", display: "none" }}
          />
          <label htmlFor={id || "file"} className="imageUpload__item" id={id}>
            {imageURLs.length < 1 ? (
              <React.Fragment>
                <div className="imageUpload__add">
                  <span>+</span>
                </div>
                <ReactSVG
                  className="imageUpload__svg"
                  src={`/svg/icon-image.svg`}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="imageUpload__add">
                  <FiEdit2 />
                </span>
                <ConnectForm>
                  <Lazy>
                    <img
                      className="imageUpload__svg imageUploded"
                      src={optimizeCloudinaryImage({
                        url: imageURLs[0],
                        transformations: ["profile"],
                      })}
                      alt="logo"
                    />
                  </Lazy>
                </ConnectForm>
              </React.Fragment>
            )}
          </label>
        </div>
        {imageURLs.length > 0 && (
          <React.Fragment>
            <Button
              type="button"
              tertiary
              label="Delete"
              onClick={() => setImages([])}
            />

            <button
              type="button"
              onClick={() => uploadImage()}
              style={{ display: "none" }}
            >
              upload
            </button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
