import React from "react";
import classNames from "classnames";

import { Typography } from "../Typography";

import { SliderProps } from "./types";

import "./styles.scss";

export const Slider = (props: SliderProps) => {
  const { defaultValue = 0, max = 100, step = 1, min = 0, className, title, onChange } = props;

  const classnames = classNames("slider", {
    [`${className}`]: className,
  });

  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div
      data-testid="slider-range"
      className={classnames}>
      {title && (
        <Typography
          Tag="p"
          classname="slider__label">
          {title}
        </Typography>
      )}
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => setValue(Number(e.target.value))}
        className="slider__input"
      />
    </div>
  );
}
