import { sha256 } from "js-sha256";
import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { v4 as uuidv4 } from "uuid";

import { DOMAINS } from "@/sites/domains";
import {
  UploadImagesProps,
  UploadLargeVideoProps,
  UploadVideosProps,
} from "./types";
import { isClient } from "@/utils/browser";
import {
  base64ToBlob,
  blobToBase64,
  fileRequireToBeChunked,
} from "@/utils/uploadHelper";
import { isFileAblob } from "@/utils/qrEditorHelper";
import { getCloudinarySignature } from "@/actions/getCloudinarySignature";

const CLOUDINARY_MAX_UNCHUNKED_VIDEO_SIZE = 20000;
const STAGE = "stage";
const LOCALHOST = "localhost";
const STAGE_LOCAL = "stage/local";
const MULTISTAGE = "multistage";

const getCloudinaryFolder = (folder: string, prefixPublicId?: string) => {
  const hostname = isClient() ? window.location.hostname : undefined;
  const sanitizedHostname = hostname?.replace("www.", "");

  if (!sanitizedHostname) folder;

  let cloudinaryFolder = folder;

  if (sanitizedHostname.split(".").includes(STAGE)) {
    cloudinaryFolder = STAGE;
  } else if (sanitizedHostname.split(".")[0].includes(LOCALHOST)) {
    cloudinaryFolder = STAGE_LOCAL;
  } else if (!Object.keys(DOMAINS).includes(sanitizedHostname)) {
    cloudinaryFolder = `${MULTISTAGE}/${sanitizedHostname.split(".")[0]}`;
  }

  if (prefixPublicId && cloudinaryFolder) {
    return `${cloudinaryFolder}/${prefixPublicId}`;
  } else if (prefixPublicId) {
    return prefixPublicId;
  } else {
    return cloudinaryFolder;
  }
};

const generateAssetHash = (asset: string, prefixPublicId: string | null) => {
  let assetString = asset;

  if (prefixPublicId) {
    assetString = `${prefixPublicId}-${asset}`;
  } else {
    const randomString = uuidv4();
    assetString = `${randomString}-${asset}`;
  }

  const hash = sha256(assetString);
  return hash;
};

export async function uploadImages({
  image,
  prefixPublicId: prefixPublicIdProp,
  customId,
  defaultFolder,
}: UploadImagesProps) {
  const cloudinaryBaseUrl = process.env.NEXT_PUBLIC_CLOUDINARY_API_BASE_URL;
  const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
  const publicId = generateAssetHash(image, prefixPublicIdProp);
  const prefixPublicId = prefixPublicIdProp;

  const cloudinaryFolder = getCloudinaryFolder(defaultFolder, prefixPublicId);

  const { folder, signature, timestamp, backup } =
    (await getCloudinarySignature(cloudinaryFolder, customId, {
      publicId,
      prefixPublicId,
    })) || {};

  const uploadUrl = `${cloudinaryBaseUrl}/${cloudName}/image/upload`;

  const axiosRequestConfig: AxiosRequestConfig = {
    method: "post",
    url: uploadUrl,
    data: {
      api_key: process.env.NEXT_PUBLIC_CLOUDINARY_API_KEY,
      backup,
      context: customId !== undefined ? `id=${customId}` : undefined,
      file: image,
      folder,
      public_id: publicId,
      signature,
      timestamp,
      upload_preset: process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET,
    },
  };

  return axios(axiosRequestConfig);
}

export async function uploadVideos({
  video,
  prefixPublicId,
  customId,
  uploaderContext,
  defaultFolder,
}: UploadVideosProps) {
  const cloudinaryBaseUrl = process.env.NEXT_PUBLIC_CLOUDINARY_API_BASE_URL;
  const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
  const publicId = generateAssetHash(video, prefixPublicId);

  const cloudinaryFolder = getCloudinaryFolder(defaultFolder, prefixPublicId);

  const { folder, signature, timestamp, backup } =
    (await getCloudinarySignature(cloudinaryFolder, customId, {
      publicId,
      prefixPublicId,
    })) || {};

  const uploadUrl = `${cloudinaryBaseUrl}/${cloudName}/video/upload`;
  uploaderContext.setIsLoading(true, Number(customId));
  if (fileRequireToBeChunked(video, CLOUDINARY_MAX_UNCHUNKED_VIDEO_SIZE)) {
    const videoBlob = await base64ToBlob(video);
    const chunkedVideos = await uploadLargeVideo({
      video: videoBlob,
      customId,
      uploaderContext,
      defaultFolder,
      prefixPublicId,
    });

    try {
      const responses = await Promise.all(chunkedVideos);
      const responseWithData = responses.filter((res) => res.data.secure_url);

      const response = await new Promise((resolve, reject) => {
        if (responseWithData.length > 0) {
          resolve(responseWithData[0]);
        } else {
          reject(undefined);
        }
      });
      uploaderContext.setIsLoading(false, Number(customId));
      return response;
    } catch (error) {
      const response = await new Promise((_, reject) => {
        reject(undefined);
      });
      uploaderContext.setIsLoading(false, Number(customId));
      return response;
    }
  } else {
    const formData = new FormData();
    formData.append("api_key", process.env.NEXT_PUBLIC_CLOUDINARY_API_KEY);
    formData.append("backup", Boolean(backup).toString());
    formData.append("context", `id=${customId}`);
    formData.append("file", video);
    if (folder !== undefined) formData.append("folder", folder);
    formData.append("public_id", publicId);
    formData.append("signature", signature);
    formData.append("timestamp", timestamp?.toString());
    formData.append(
      "upload_preset",
      process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET
    );
    formData.append("flags", "context");

    const axiosRequestConfig: AxiosRequestConfig = {
      method: "post",
      url: uploadUrl,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,

      onUploadProgress(progressEvent) {
        const progressPercentage = Math.round(
          (100 * progressEvent.loaded) / progressEvent.total
        );
        uploaderContext.setProgress(Number(customId), progressPercentage);
      },
    };

    const response = await axios(axiosRequestConfig);
    uploaderContext.setIsLoading(false, Number(customId));
    return response;
  }
}

export async function uploadLargeVideo({
  video,
  prefixPublicId,
  customId,
  defaultFolder,
  uploaderContext,
}: UploadLargeVideoProps) {
  const cloudinaryBaseUrl = process.env.NEXT_PUBLIC_CLOUDINARY_API_BASE_URL;
  const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
  const base64Video = await blobToBase64(video);
  const publicId = generateAssetHash(base64Video, prefixPublicId);

  const cloudinaryFolder = getCloudinaryFolder(defaultFolder, prefixPublicId);

  const { folder, signature, timestamp, backup } =
    (await getCloudinarySignature(cloudinaryFolder, customId, {
      publicId,
      prefixPublicId,
    })) || {};

  const chunkSize = 10240000;
  const totalSize = video.size;
  const numChunks = Math.ceil(totalSize / chunkSize);

  const uploadUrl = `${cloudinaryBaseUrl}/${cloudName}/video/upload`;

  const uploadPromises: AxiosPromise[] = [];
  uploaderContext.initChunks(Number(customId), numChunks);
  for (let i = 0; i < numChunks; i++) {
    const startByte = i * chunkSize;
    const endByte = Math.min((i + 1) * chunkSize - 1, totalSize - 1);

    const chunk = video.slice(startByte, endByte + 1);
    const headers = {
      "X-Unique-Upload-Id": publicId,
      "Content-Range": `bytes ${startByte}-${endByte}/${totalSize}`,
    };

    const formData = new FormData();
    formData.append("api_key", process.env.NEXT_PUBLIC_CLOUDINARY_API_KEY);
    formData.append("backup", Boolean(backup).toString());
    formData.append("context", `id=${customId}`);
    formData.append("file", chunk);
    if (folder !== undefined) formData.append("folder", folder);
    formData.append("public_id", publicId);
    formData.append("signature", signature);
    formData.append("timestamp", timestamp?.toString());
    formData.append(
      "upload_preset",
      process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET
    );
    formData.append("flags", "context");

    const axiosRequestConfig: AxiosRequestConfig = {
      method: "post",
      url: uploadUrl,
      headers,
      data: formData,
      onUploadProgress(progressEvent) {
        const progressPercentage = Math.round(
          (100 * progressEvent.loaded) / progressEvent.total
        );
        uploaderContext.setChunkProgress(
          Number(customId),
          i,
          progressPercentage
        );
      },
    };
    const data = axios(axiosRequestConfig);
    uploadPromises.push(data);
  }

  return uploadPromises;
}

export const optimizeCloudinaryImage = ({
  url,
  transformations,
  isVideo,
}: {
  url: string;
  transformations?: (
    | "quality"
    | "format"
    | "size"
    | "profile"
    | `q_${string}`
    | `f_${string}`
    | `h_${string}`
    | `w_${string}`
  )[];
  isVideo?: boolean;
}): string => {
  if (isFileAblob(url)) return url;
  if (isVideo && !url.includes("res.cloudinary.com/")) return url;

  const pattern = /(upload\/)([^/]+\/)?(v\d+\/)/;

  const transformationOptions = {
    quality: "q_auto:good",
    format: "f_auto",
    size: "w_auto,dpr_auto",
  };

  let stringOfTransformations = [
    transformationOptions.format,
    transformationOptions.quality,
    transformationOptions.size,
  ].join(",");

  if (isVideo) {
    stringOfTransformations = "q_80,f_auto";
  } else {
    if (transformations && transformations.length > 0) {
      if (transformations.includes("profile")) {
        stringOfTransformations = "c_thumb,w_100,h_100,q_30,f_auto";
      } else {
        stringOfTransformations = transformations
          .map((opt) => transformationOptions[opt] || opt)
          .join(",");
      }
    }
  }
  const finalUrl =
    url?.replace(pattern, `$1${stringOfTransformations || "myText"}/$3`) || "";

  return finalUrl;
};
