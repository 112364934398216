import React from "react";
import { filter, map, forOwn } from "lodash";
import { FaTrash } from "react-icons/fa";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "next-i18next";

import { ConnectForm, URLRegex } from "@/utils/formHelper";

import { Typography } from "@/components/Typography";
import { ImageUpload } from "@/components/ImageUpload";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";

import { FormValidationMethods } from "@/components/Form/types";
import { LinkInterface, LinksProps } from "./types";

export const LINK_ID_PREFIX = "link";
export const MAX_LINKS = 10;

import "./styles.scss";

export const Links = (props: LinksProps) => {
  const methods: FormValidationMethods = useFormContext();
  const { t } = useTranslation("listoflinks");

  const {
    title,
    titleEmpty,
    defaultValue,
    linkLabel,
    linkPlaceHolder,
    linkUrlLabel,
    linkUrlPlaceHolder,
    linksButtonAddLink,
    invalidLinkUrl,
    onClick
  } = props;

  const [addedLinks, setAddedLinks] = React.useState<LinkInterface[] | undefined>(
    defaultValue || []
  );

  const linkId = (index: number | string) => `${LINK_ID_PREFIX}-${index}`;

  const commonRules = {
    required: {
      value: true,
      message: t("_requiredMessage"),
    },
  };

  const addNewLink = () => {
    const newLink: LinkInterface = {
      id: linkId(addedLinks.length),
      linkImageUrl: "",
      linkText: "",
      linkUrl: "",
    };

    setAddedLinks([...addedLinks, newLink]);
  };

  const removeLink = (id: string | number) => {
    methods?.setValue(`text-link-${id}`, "");
    methods?.setValue(`url-link-${id}`, "");
    methods?.setValue(`image-${id}`, "");

    let newLinks = filter(addedLinks, (link) => link.id !== id);
    newLinks = map(newLinks, (link, idx) => {
      methods?.setValue(`text-link-${idx}`, link.linkText);
      methods?.setValue(`url-link-${idx}`, link.linkUrl);
      methods?.setValue(`image-${idx}`, link.linkImageUrl);

      return {
        ...link,
        id: linkId(idx),
      };
    });

    forOwn(methods?.getValues(), (value, key) => {
      if (Number(key.substring(key.length - 1)) >= newLinks.length) {
        methods?.setValue(key, "");
      }
    });

    setAddedLinks(newLinks);
  };

  const onImageLoad = (image: string, index: string | number) => {
    setAddedLinks(
      map(addedLinks, (link) => {
        return link.id === linkId(index) ? { ...link, linkImageUrl: image[0] } : link;
      })
    );
  };

  const onHandleInput = (name: string, inputType: "text" | "url") => {
    const value = methods?.getValues(name);
    setAddedLinks(
      map(addedLinks, (link) =>
        `${inputType}-${link.id}` === name
          ? inputType === "text"
            ? { ...link, linkText: value }
            : { ...link, linkUrl: value }
          : link
      )
    );
  };

  const renderLinkMessage = () => {
    return addedLinks.length !== MAX_LINKS ? (
      addedLinks.length ? (
        <Typography
          classname="u-bold"
          Tag={"p"}>
          {titleEmpty}
        </Typography>
      ) : (
        <React.Fragment>
          <Typography
            classname="u-bold"
            Tag={"p"}>
            {title}
            <em
              className={
                methods?.formState.errors["emptyListOfLinks"] ? "Links__error" : undefined
              }>
              *
            </em>
          </Typography>
          {methods?.formState.errors["emptyListOfLinks"] && (
            <p className="Links__error">{methods?.formState.errors["emptyListOfLinks"].message as string}</p>
          )}
        </React.Fragment>
      )
    ) : undefined;
  };

  React.useEffect(() => {
    onClick && onClick(addedLinks);
  }, [addedLinks]);

  React.useEffect(() => {
    if (methods?.formState.isSubmitting) {
      if (addedLinks.length === 0) {
        methods?.setError("emptyListOfLinks", {
          type: "required",
          message: t("_emptyListOfLinks"),
        });
      } else {
        methods?.clearErrors("emptyListOfLinks");
      }
    }
  }, [methods?.formState]);

  return (
    <ConnectForm>
      <div
        data-testid="Links"
        data-qa={props.dataQA}
        className="Links">
        {addedLinks.map((item, index) => {
          return (
            <div
              className={`Links__input-wrapper`}
              key={index}>
              <div className={`Links__header`}>
                <Typography
                  Tag={"p"}
                  classname="u-bold">
                  {`Link ${index + 1}`}
                </Typography>
                <Button
                  icon={<FaTrash />}
                  type="button"
                  tertiary
                  square
                  onClick={() => removeLink(item.id)}
                  ariaLabel="delete"
                />
              </div>
              <ImageUpload
                title="Image"
                id={`image-${index}`}
                onClick={(image) => onImageLoad(image, index)}
                defaultValue={item.linkImageUrl}
              />
              <Controller
                control={methods?.control}
                name={`text-${item.id}`}
                render={() => (
                  <Input
                    id={`text-${item.id}`}
                    type="text"
                    name={`text-${item.id}`}
                    label={linkLabel}
                    placeholder={linkPlaceHolder}
                    defaultValue={item.linkText}
                    onChange={() => onHandleInput(`text-${item.id}`, "text")}
                    error={methods?.formState.errors[`text-${item.id}`]}
                    errorMessage={methods?.formState.errors[`text-${item.id}`]?.message}
                  />
                )}
                rules={{
                  ...commonRules,
                  maxLength: {
                    value: 50,
                    message: t("_linksMaxLength"),
                  },
                  pattern: {
                    value: new RegExp("^\\S.*$[ A-Za-z0-9_@./#&+,-]*$"),
                    message: t("_oneChar"),
                  },
                }}
              />
              <Controller
                control={methods?.control}
                name={`url-${item.id}`}
                render={() => (
                  <Input
                    id={`url-${item.id}`}
                    type="text"
                    name={`url-${item.id}`}
                    label={linkUrlLabel}
                    placeholder={linkUrlPlaceHolder}
                    defaultValue={item.linkUrl}
                    onChange={() => onHandleInput(`url-${item.id}`, "url")}
                    error={methods?.formState.errors[`url-${item.id}`]}
                    errorMessage={methods?.formState.errors[`url-${item.id}`]?.message}
                  />
                )}
                rules={{
                  ...commonRules,
                  pattern: {
                    value: new RegExp(URLRegex),
                    message: invalidLinkUrl,
                  },
                }}
              />
            </div>
          );
        })}
        {renderLinkMessage()}
        <Button
          label={linksButtonAddLink}
          type="button"
          onClick={() => addNewLink()}
          disabled={addedLinks.length === MAX_LINKS}
          tertiary
          full
          ariaLabel="add"
        />
      </div>
    </ConnectForm>
  );
}
