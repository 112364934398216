import {
  includes,
  forEach,
  map,
  isArray,
  filter,
  isObject,
  find,
  cloneDeep,
  clone,
  orderBy,
} from "lodash";
import { QREditorContextProps } from "@/contexts/QREditorContext/types";
import {
  QRTypeName,
  SocialNetwork,
  QRAppsType,
  QRBusinessType,
  QRImageType,
  QRLinksType,
  QRPDFType,
  QRUrlType,
  QRVideoType,
  QRYoutubeType,
  QRWebsiteType,
  QRWifiType,
  QRStyle,
  QRVcardType,
  QRSocialType,
  QRSocialMedia,
  QREventType,
  EventDateTime,
  Icon_Facility,
} from "@/types/qr";
import { QRAppsAPIPayload } from "../types/qr/apps";
import { QRBusinessAPIPayload, OpeningHoursDay } from "../types/qr/business";
import { QRImagesAPIPayload } from "../types/qr/images";
import { QRLinksAPIPayload } from "../types/qr/links";
import { QRPdfAPIPayload } from "../types/qr/pdf";
import { QRUrlAPIPayload } from "../types/qr/url";
import { QRVideoAPIPayload } from "../types/qr/video";
import { QRWebsiteAPIPayload } from "../types/qr/website";
import { QRWifiAPIPayload } from "../types/qr/wifi";
import { QRYoutubeAPIPayload } from "../types/qr/youtube";
import { QRVcardAPIPayload } from "../types/qr/vcard";
import { QRSocialAPIPayload, SocialChannel } from "../types/qr/social";
import { API_Facility, FacilitiesObjectType, QREventAPIPayload } from "../types/qr/event";
import { RestApiRequest, QRStyleAPIPayload } from "../types/types";
import { FileProps } from "@components/FilesUpload/types";
import { BusinessHoursDay, DEFAULT_TIME } from "@/components/BusinessHours/types";
import { checkValidURL } from "@/utils/formHelper";
import { uploadImages, uploadVideos } from "@/services/CloudinaryService";
import { isFileAblob } from "@/utils/qrEditorHelper";
import { FilterOrderTypeValue } from "@/contexts/FiltersContext/types";
import { LoadingBarContextProps } from "@/contexts/LoadingBarContext/types";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_QR_CODE_NAME = "Your QR Code";

export class QREditorDataToAPIConversor {
  static async getPayload(
    qrEditorContext: QREditorContextProps<any>,
    filesUploaderContext: LoadingBarContextProps
  ) {
    let result:
      | Promise<{ payload: RestApiRequest<any>; newData: any }>
      | { payload: RestApiRequest<any>; newData: any }
      | undefined;

    const data = qrEditorContext?.qrData.data;
    const style = qrEditorContext?.qrData.qrStyle;

    const typeName =
      qrEditorContext?.qrData.data.typeName === "Images' Gallery"
        ? "Image"
        : qrEditorContext?.qrData.data.typeName;
    switch (typeName as QRTypeName) {
      case "Apps":
        result = this.qrApp(data, style, qrEditorContext);
        break;
      case "Business":
        result = this.qrBusiness(data, style, qrEditorContext);
        break;
      case "Image":
        result = this.qrImage(data, style, qrEditorContext);
        break;
      case "Links":
        result = this.qrLinks(data, style, qrEditorContext);
        break;
      case "PDF":
        result = this.qrPdf(data, style, qrEditorContext);
        break;
      case "URL":
        result = this.qrUrl(data, style, qrEditorContext);
        break;
      case "Video":
        result = this.qrVideo(data, style, qrEditorContext, filesUploaderContext);
        break;
      case "Website":
        result = this.qrWebsite(data, style, qrEditorContext);
        break;
      case "Wifi":
        result = this.qrWifi(data, style, qrEditorContext);
        break;
      case "YouTube":
        result = this.qrYoutube(data, style, qrEditorContext);
        break;
      case "vCard":
        result = this.qrVcard(data, style, qrEditorContext);
        break;
      case "Social Media":
        result = this.qrSocialMedia(data, style, qrEditorContext);
        break;
      case "Event":
        result = this.qrEvent(data, style, qrEditorContext);
        break;
      default:
        result = undefined;
        break;
    }

    return result;
  }

  static getQRStylePayload = async (style: QRStyle): Promise<QRStyleAPIPayload> => {
    const {
      backgroundColor,
      dotsColor,
      dotsStyle,
      cornerBackgroundColor,
      cornerBorderColor,
      cornerStyle,
      logoUrl,
      frameBackgroundColor,
      frameColor,
      frameStyle,
      frameText,
      frameTextColor,
      styleType,
    } = style;

    const uploadedLogo = await this.fileToCloud(logoUrl);

    return {
      backgroundColor,
      dotsStyle,
      dotsColor,
      cornerStyle: cornerStyle,
      cornerBorderColor,
      cornerBackgroundColor,
      imageUrl: uploadedLogo,
      frameBackgroundColor,
      frameColor,
      frameStyle,
      frameText,
      frameTextColor,
      styleType,
    };
  };

  private static getColor = (themeColor) => {
    return {
      background: themeColor.backgroundColor,
      button: themeColor.buttonColor,
    };
  };

  private static getSocials = (socials: SocialNetwork[]) => {
    const socialNetworks = {};
    forEach(
      socials,
      (social) => (socialNetworks[social.name.toLowerCase()] = checkValidURL(social.value)|| "")
    );

    return socialNetworks;
  };

  private static getSocialsExtended = (socials: QRSocialMedia[]): SocialChannel[] => {
    return socials.map((social) => {
      const { id, text, url } = social;
      return {
        identifier: id,
        url: checkValidURL(url.value)|| "",
        ...(text?.value ? { text: text.value } : {}),
      } as SocialChannel;
    });
  };

  private static getFacilities = (facilities: Icon_Facility[]): FacilitiesObjectType => {
    const formattedFacilities: FacilitiesObjectType = {};

    const facilityNames = {
      [Icon_Facility.accommodation]: API_Facility.accommodation,
      [Icon_Facility.bar]: API_Facility.bar,
      [Icon_Facility.cafe]: API_Facility.cafe,
      [Icon_Facility.childFriendly]: API_Facility.babyStroller,
      [Icon_Facility.parking]: API_Facility.parking,
      [Icon_Facility.petFriendly]: API_Facility.petFriendly,
      [Icon_Facility.restaurant]: API_Facility.restaurant,
      [Icon_Facility.restrooms]: API_Facility.toilet,
      [Icon_Facility.seating]: API_Facility.restRooms,
      [Icon_Facility.nearPublicTransport]: API_Facility.bus,
      [Icon_Facility.taxi]: API_Facility.taxi,
      [Icon_Facility.wheelchairAccess]: API_Facility.wheelchairAccess,
      [Icon_Facility.wifi]: API_Facility.wifi,
    };

    forEach(facilities, (facility) => (formattedFacilities[facilityNames[facility]] = true));

    return formattedFacilities;
  };

  private static getFormattedBusinessHour = (
    day: BusinessHoursDay | undefined
  ): OpeningHoursDay => {
    if (!day) return undefined;

    const newDay = cloneDeep(day);

    newDay.times = map(newDay.times, (time) => ({
      start: time.start || DEFAULT_TIME,
      end: time.end || DEFAULT_TIME,
    }));

    return newDay;
  };

  private static getDateIsoString = (
    key: "start" | "end",
    eventDateTime: EventDateTime
  ): string => {
    if (!eventDateTime || !eventDateTime[key]) return undefined;

    const date = eventDateTime[key].date;
    const time = eventDateTime[key].time;

    if (!date) return undefined;

    const { allDay } = eventDateTime;

    if (allDay || !time) {
      return date;
    }

    const [hours, minutes] = time.split(":");

    const utcDate = dayjs(date).utc(true);
    const utcDateWithTime = utcDate
      .hour(Number(hours) ?? 0)
      .minute(Number(minutes) ?? 0)
      .toISOString();

    return utcDateWithTime;
  };

  private static async fileToCloud(files: string | string[], qrId?: string) {
    function blobToBase64(blobs: string[]) {
      return map(blobs, async (blob) => {
        let newBlob;

        if (isFileAblob(blob)) {
          const response = await fetch(blob);
          newBlob = await response.blob();
        } else {
          newBlob = blob;
        }

        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(newBlob);
        });
      });
    }

    let urls;
    if (isArray(files)) {
      const urlsToUploadIndex = [];
      const urlsToUpload = filter(files, (file, idx) => {
        urlsToUploadIndex.push(idx);
        return isFileAblob(file);
      });
      const persistedUrls = filter(files, (file) => !isFileAblob(file));
      const arrayOfBase64 = await Promise.all(blobToBase64(urlsToUpload));
      const arrayOfPromises =
        arrayOfBase64.length > 0
          ? map(arrayOfBase64, (file: string, idx) =>
              uploadImages({
                image: file,
                customId: urlsToUploadIndex[idx].toString(),
                prefixPublicId: qrId || null,
              })
            )
          : [];
      const results = arrayOfPromises.length > 0 ? await Promise.all(arrayOfPromises) : [];

      urls = [...persistedUrls, ...map(results, (result) => result.data.secure_url)];
    } else {
      if (isFileAblob(files)) {
        const filesBlob = await Promise.all(blobToBase64([files]));
        const result = await uploadImages({
          image: filesBlob[0] as string,
          prefixPublicId: qrId || null,
        });
        urls = result.data.secure_url;
      } else {
        urls = files;
      }
    }

    return urls;
  }

  private static async uploadToCloudinary(
    files: { id: string; url: string }[],
    type?: "video" | "image",
    filesUploaderContext?: LoadingBarContextProps,
    prefixPublicId?: string
  ) {
    function blobToBase64(blobs: { id: string; url: string }[]) {
      return map(blobs, async (blob) => {
        const response = await fetch(blob.url);
        const newBlob = await response.blob();

        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({ id: blob.id, url: reader.result });
          };
          reader.readAsDataURL(newBlob);
        });
      });
    }

    const noBlobUrls = files.filter((file) => !isFileAblob(file.url));

    const blobUrls = await Promise.all(blobToBase64(files.filter((file) => isFileAblob(file.url))));
    const cloudinaryObjects = map(blobUrls, (file: { id: string; url: string }) =>
      type === "video"
        ? // ? uploadVideos(file.url, file.id, filesUploaderContext)
          uploadVideos({
            video: file.url,
            customId: file.id,
            prefixPublicId,
            uploaderContext: filesUploaderContext,
          })
        : uploadImages({ image: file.url, customId: file.id, prefixPublicId })
    );

    let cloudinaryImageResponses;
    try {
      cloudinaryImageResponses = await Promise.all(cloudinaryObjects);
    } catch (error) {
      cloudinaryImageResponses = undefined;
    }

    const cloudinaryImages = cloudinaryImageResponses
      ? map(cloudinaryImageResponses, (imageResponse) => {
          const isEager = imageResponse.data.eager && imageResponse.data.eager[0];

          return {
            id: imageResponse.data.context?.custom?.id,
            url: isEager ? isEager.secure_url : imageResponse.data.secure_url,
          };
        })
      : undefined;

    const urls = cloudinaryImages ? [...noBlobUrls, ...cloudinaryImages] : undefined;

    return urls ? orderBy(urls, "id", FilterOrderTypeValue.ASC) : undefined;
  }

  private static async qrApp(
    data: QRAppsType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"apps">
  ) {
    const {
      appLinks,
      appsCompany,
      appsDescription,
      appsName,
      appsWebsite,
      logoUrl,
      qrName,
      themeColor,
    } = data;

    const file = await this.fileToCloud(logoUrl, qrEditorContext?.qrData?.qrCodeId);
    const qrStyle = await this.getQRStylePayload(style);

    const newData = {
      ...qrEditorContext.qrData,
      data: {
        ...qrEditorContext.qrData.data,
        logoUrl: file,
      },
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const appsLinksExist = appLinks && appLinks.length > 0;
    const payload: RestApiRequest<QRAppsAPIPayload> = {
      type: "apps",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        "info-company": appsCompany,
        "info-description": appsDescription,
        "info-logo": file,
        "info-name": appsName,
        "info-website": checkValidURL(appsWebsite)|| "",
        ...(appsLinksExist && {
          apps: appsLinksExist && {
            amazon: checkValidURL(find(appLinks, { id: "amazon" })?.value),
            google: checkValidURL(find(appLinks, { id: "google" })?.value),
            apple: checkValidURL(find(appLinks, { id: "apple" })?.value),
          },
        }),
        color: this.getColor(themeColor),
        welcome: "",
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrBusiness(
    data: QRBusinessType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"business">
  ) {
    const {
      logoUrl,
      qrName,
      themeColor,
      aboutTheCompany,
      businessHours,
      address,
      buttonText,
      buttonUrl,
      city,
      companyName,
      contactEmail,
      contactName,
      contactPhone,
      contactWebsite,
      country,
      facilities,
      numeration,
      socialNetworks,
      state,
      subtitle,
      title,
      zipCode,
    } = data;

    const file = await this.fileToCloud(logoUrl, qrEditorContext?.qrData?.qrCodeId);
    const qrStyle = await this.getQRStylePayload(style);
    const newData = {
      ...qrEditorContext.qrData,
      data: {
        ...qrEditorContext.qrData.data,
        logoUrl: file,
      },
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRBusinessAPIPayload> = {
      type: "business",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        color: this.getColor(themeColor),
        "info-logo": file,
        "info-company": companyName,
        "info-title": title,
        "info-subtitle": subtitle,
        "info-action-title": buttonText,
        "info-action-url": checkValidURL(buttonUrl)|| "",
        "opening-hours-format": businessHours
          ? businessHours.format === "AM/PM"
            ? "AM-PM"
            : "24-HOURS"
          : undefined,
        "opening-hours-day-monday": this.getFormattedBusinessHour(businessHours?.days.monday),
        "opening-hours-day-tuesday": this.getFormattedBusinessHour(businessHours?.days.tuesday),
        "opening-hours-day-wednesday": this.getFormattedBusinessHour(businessHours?.days.wednesday),
        "opening-hours-day-thursday": this.getFormattedBusinessHour(businessHours?.days.thursday),
        "opening-hours-day-friday": this.getFormattedBusinessHour(businessHours?.days.friday),
        "opening-hours-day-saturday": this.getFormattedBusinessHour(businessHours?.days.saturday),
        "opening-hours-day-sunday": this.getFormattedBusinessHour(businessHours?.days.sunday),
        "location-address": address,
        "location-numeration": numeration,
        "location-postal-code": zipCode,
        "location-city": city,
        "location-state": state,
        "location-country": country,
        facilities: this.getFacilities(facilities),
        "contact-name": contactName,
        "contact-phone": contactPhone,
        "contact-email": contactEmail,
        "contact-website": checkValidURL(contactWebsite)|| "",
        about: aboutTheCompany,
        social: this.getSocials(socialNetworks),
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrImage(
    data: QRImageType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"image">
  ) {
    const {
      albumDescription,
      albumTitle,
      albumWebsite,
      buttonText,
      buttonUrl,
      imageUrls,
      qrName,
      themeColor,
    } = data;

    const files = await this.fileToCloud(
      map(imageUrls, (image) => image.url) as string[],
      qrEditorContext?.qrData?.qrCodeId
    );
    const qrStyle = await this.getQRStylePayload(style);

    const getUrl = (index: number) => {
      if (files.length > 0) {
        if (isObject(files[index])) {
          return find(files, (file) => includes(file.public_id, `_${index}`))?.secure_url;
        } else {
          return files[index];
        }
      } else {
        return files;
      }
    };

    const clonedQrEditor = cloneDeep(qrEditorContext.qrData);
    const newData = {
      ...clonedQrEditor,
      data: {
        ...clonedQrEditor.data,
        imageUrls: map(imageUrls, (image, idx) => {
          return {
            ...image,
            url: getUrl(idx),
          };
        }),
      },
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRImagesAPIPayload> = {
      type: "image-gallery",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        color: this.getColor(themeColor),
        images:
          newData.data.imageUrls &&
          map(newData.data.imageUrls, (file) => ({
            url: file.url as string,
          })),
        "information-title": albumTitle,
        "information-description": albumDescription,
        "information-website": checkValidURL(albumWebsite)|| "",
        "information-button-text": buttonText,
        "information-url": checkValidURL(buttonUrl)|| "",
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrLinks(
    data: QRLinksType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"links">
  ) {
    const { qrName, themeColor, description, links, logoUrl, socialNetworks, title } = data;

    const logo = await this.fileToCloud(logoUrl, qrEditorContext?.qrData?.qrCodeId);
    const files = await this.uploadToCloudinary(
      map(links, (link) => {
        return { id: link.id.toString(), url: link.linkImageUrl || undefined };
      }),
      "image",
      undefined,
      qrEditorContext?.qrData?.qrCodeId || null
    );
    const qrStyle = await this.getQRStylePayload(style);

    const getLinksData = () => {
      return files.map((file) => {
        const linkInfo = find(links, (link) => link.id === file.id);

        return {
          id: file.id,
          linkImageUrl: file.url || undefined,
          linkText: linkInfo.linkText || undefined,
          linkUrl: checkValidURL(linkInfo.linkUrl) || "",
        };
      });
    };

    const clonedQrEditor = cloneDeep(qrEditorContext.qrData);
    const newData = {
      ...clonedQrEditor,
      data: {
        ...clonedQrEditor.data,
        logoUrl: logo,
        links: getLinksData(),
      },
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRLinksAPIPayload> = {
      type: "list-links",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        color: this.getColor(themeColor),
        "information-logo": logo,
        "information-title": title,
        "information-description": description,
        links:
          newData.data.links &&
          map(newData.data.links, (link) => {
            return {
              image: link.linkImageUrl,
              text: link.linkText,
              url: checkValidURL(link.linkUrl)|| "",
            };
          }),
        social: this.getSocials(socialNetworks),
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrPdf(
    data: QRPDFType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"pdf">
  ) {
    const { companyName, pdfDescription, pdfFileUrl, pdfName, websiteUrl, qrName, themeColor } =
      data;

    const file = await this.fileToCloud(pdfFileUrl, qrEditorContext?.qrData?.qrCodeId);
    const qrStyle = await this.getQRStylePayload(style);

    const newData = {
      ...qrEditorContext.qrData,
      data: {
        ...qrEditorContext.qrData.data,
        pdfFileUrl: file,
      },
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRPdfAPIPayload> = {
      type: "pdf",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        "pdf-upload": file,
        color: this.getColor(themeColor),
        "info-company": companyName,
        "info-name": pdfName,
        "info-description": pdfDescription,
        "info-website": checkValidURL(websiteUrl)|| "",
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrUrl(
    data: QRUrlType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"url">
  ) {
    const { qrName, qrUrlLink } = data;

    const qrStyle = await this.getQRStylePayload(style);
    const newData = {
      ...clone(qrEditorContext.qrData),
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRUrlAPIPayload> = {
      type: "url",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        "field-url": checkValidURL(qrUrlLink)|| "",
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrVcard(
    data: QRVcardType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"vcard">
  ) {
    const {
      qrName,
      themeColor,
      logoUrl,
      fullName,
      email,
      mobilePhone,
      landlinePhone,
      fax,
      website,
      companyName,
      profession,
      summary,
      address,
      numeration,
      zipCode,
      city,
      state,
      country,
      socialNetworks,
    } = data;

    const file = await this.fileToCloud(logoUrl, qrEditorContext?.qrData?.qrCodeId);
    const qrStyle = await this.getQRStylePayload(style);
    const newData = {
      ...qrEditorContext.qrData,
      data: {
        ...qrEditorContext.qrData.data,
        logoUrl: file,
      },
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRVcardAPIPayload> = {
      type: "vcard",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        color: this.getColor(themeColor),
        "info-image": file,
        "info-full-name": fullName,
        "info-email": email,
        "info-mobile-phone": mobilePhone,
        "info-landline-phone": landlinePhone,
        "info-fax": fax,
        "info-website": website,
        "info-company-name": companyName,
        "info-profession": profession,
        "info-summary": summary,
        "info-address": address,
        "info-numeration": numeration,
        "info-zip-code": zipCode,
        "info-city": city,
        "info-state": state,
        "info-country": country,
        social: this.getSocials(socialNetworks),
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrVideo(
    data: QRVideoType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"video">,
    filesUploaderContext: LoadingBarContextProps
  ) {
    const {
      qrName,
      buttonText,
      buttonUrl,
      videoUrls,
      companyName,
      description,
      socialNetworks,
      themeColor,
      title,
    } = data;
    const indexedVideoUrls = map(videoUrls, (video, idx) => ({ ...video, id: idx.toString() }));

    const files = await this.uploadToCloudinary(
      map(indexedVideoUrls, (video) => {
        return { id: video.id, url: video.url as string };
      }),
      "video",
      filesUploaderContext,
      qrEditorContext?.qrData?.qrCodeId || null
    );

    if (!files) {
      qrEditorContext.setIsQrLoading(false);
    }

    const qrStyle = await this.getQRStylePayload(style);

    const getVideosData = (): FileProps[] => {
      if (!files) return undefined;

      const filesData = files.map((file) => {
        const videoInfo = find(indexedVideoUrls, (video) => video.id === file.id);

        return {
          id: file.id,
          name: videoInfo.name,
          size: videoInfo.size,
          url: checkValidURL(file.url as string) || "",
          type: "video",
        };
      });

      return orderBy(filesData, "id", FilterOrderTypeValue.ASC);
    };

    const clonedQrEditor = cloneDeep(qrEditorContext.qrData);
    const newData = {
      ...clonedQrEditor,
      data: {
        ...clonedQrEditor.data,
        videoUrls: getVideosData(),
      },
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRVideoAPIPayload> = newData.data.videoUrls
      ? {
          type: "video",
          content: {
            "qr-name": qrName || DEFAULT_QR_CODE_NAME,
            color: this.getColor(themeColor),
            "info-company": companyName,
            "info-name": title,
            "info-description": description,
            "info-button": buttonText,
            "info-url": checkValidURL(buttonUrl)|| "",
            videos:
              newData.data.videoUrls &&
              map(newData.data.videoUrls, (file) => ({ url: file.url as string, name: file.name })),
            social: this.getSocials(socialNetworks),
          },
          style: qrStyle,
          aiSelectedImage:
            qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
        }
      : undefined;

    return { payload, newData };
  }

  private static async qrYoutube(
    data: QRYoutubeType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"youtube">
  ) {
    const { qrName, youtubeUrl } = data;

    const qrStyle = await this.getQRStylePayload(style);
    const newData = {
      ...clone(qrEditorContext.qrData),
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRYoutubeAPIPayload> = {
      type: "youtube",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        "youtube-url": checkValidURL(youtubeUrl)|| "",
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrWebsite(
    data: QRWebsiteType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"website">
  ) {
    const {
      qrName,
      buttonText,
      buttonUrl,
      companyName,
      logoUrl,
      socialNetworks,
      subtitle,
      themeColor,
      title,
    } = data;

    const file = await this.fileToCloud(logoUrl, qrEditorContext?.qrData?.qrCodeId);
    const qrStyle = await this.getQRStylePayload(style);

    const newData = {
      ...qrEditorContext.qrData,
      data: {
        ...qrEditorContext.qrData.data,
        logoUrl: file,
      },
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRWebsiteAPIPayload> = {
      type: "website",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        color: this.getColor(themeColor),
        "info-image": file,
        "info-company": companyName,
        "info-title": title,
        "info-subtitle": subtitle,
        "info-action-title": buttonText,
        "info-action-url": checkValidURL(buttonUrl)|| "",
        social: this.getSocials(socialNetworks),
        welcome: "",
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrWifi(
    data: QRWifiType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"wifi">
  ) {
    const { qrName, qrNetworkHidden, qrNetworkName, qrNetworkPassword, qrNetworkSecurityType } =
      data;

    const qrStyle = await this.getQRStylePayload(style);
    const newData = {
      ...clone(qrEditorContext.qrData),
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRWifiAPIPayload> = {
      type: "wifi",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        "network-name": qrNetworkName,
        "network-password": qrNetworkSecurityType === "No password" ? "" : qrNetworkPassword,
        "network-security-type":
          qrNetworkSecurityType === "No password" ? undefined : qrNetworkSecurityType,
        "network-hidden-password": Boolean(qrNetworkHidden),
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrSocialMedia(
    data: QRSocialType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"social">
  ) {
    const { qrName, themeColor, headline, description, links, logoUrl } = data;

    const image = await this.fileToCloud(logoUrl, qrEditorContext?.qrData?.qrCodeId);
    const qrStyle = await this.getQRStylePayload(style);

    const clonedQrEditor = cloneDeep(qrEditorContext.qrData);
    const newData = {
      ...clonedQrEditor,
      data: {
        ...clonedQrEditor.data,
        logoUrl: image,
      },
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QRSocialAPIPayload> = {
      type: "social-media",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        color: this.getColor(themeColor),
        "info-logo": image,
        "info-headline": headline,
        "info-description": description,
        "channels-social": this.getSocialsExtended(links),
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }

  private static async qrEvent(
    data: QREventType,
    style: QRStyle,
    qrEditorContext: QREditorContextProps<"wifi">
  ) {
    const {
      qrName,
      themeColor,
      logoUrl,
      infoTitle,
      infoDescription,
      infoActionTitle,
      infoActionUrl,
      eventDateTime,
      locationAddress,
      locationNumeration,
      locationPostalCode,
      locationCity,
      locationState,
      locationCountry,
      organizerName,
      organizerPhone,
      organizerEmail,
      organizerWebsite,
      organizerAbout,
      facilities,
    } = data;

    const image = await this.fileToCloud(logoUrl);
    const qrStyle = await this.getQRStylePayload(style);

    const clonedQrEditor = cloneDeep(qrEditorContext.qrData);
    const newData = {
      ...clonedQrEditor,
      data: {
        ...clonedQrEditor.data,
        logoUrl: image,
      },
      qrStyle: { ...qrStyle, logoUrl: qrStyle.imageUrl },
      ai: qrEditorContext.qrData?.ai,
    };

    const payload: RestApiRequest<QREventAPIPayload> = {
      type: "event",
      content: {
        "qr-name": qrName || DEFAULT_QR_CODE_NAME,
        color: this.getColor(themeColor),
        "info-image": image,
        "info-title": infoTitle,
        "info-description": infoDescription,
        "info-action-title": infoActionTitle,
        "info-action-url": checkValidURL(infoActionUrl)|| "",
        "time-format": eventDateTime?.format,
        "time-all-day": eventDateTime?.allDay,
        "time-timezone": eventDateTime?.timezone,
        "time-start": this.getDateIsoString("start", eventDateTime),
        "time-end": this.getDateIsoString("end", eventDateTime),
        "time-action-title": eventDateTime?.actionTitle,
        "location-address": locationAddress,
        "location-numeration": locationNumeration,
        "location-postal-code": locationPostalCode,
        "location-city": locationCity,
        "location-state": locationState,
        "location-country": locationCountry,
        "organizer-name": organizerName,
        "organizer-phone": organizerPhone,
        "organizer-email": organizerEmail,
        "organizer-website": checkValidURL(organizerWebsite)|| "",
        "organizer-about": organizerAbout,
        facilities: this.getFacilities(facilities),
      },
      style: qrStyle,
      aiSelectedImage:
        qrStyle.styleType === "AI" ? qrEditorContext.qrData?.ai?.selectedImageUrl : undefined,
    };

    return { payload, newData };
  }
}
